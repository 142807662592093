import React from 'react'

import { StyledLink } from '../../styled-components/base'
import { EndPoint } from './header-nav'

export const Footer = () => (
  <div className="bg-white realtive">
    <div className="border-t border-gray-100"></div>
    <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
        <div className="px-5 py-2">
          <StyledLink
            to={EndPoint.CORTIZ_FITNESS_HOME}
            className="text-base leading-6 text-gray-500 hover:text-gray-900"
          >
            Cortiz Fitness
          </StyledLink>
        </div>
        <div className="px-5 py-2">
          <StyledLink
            to={EndPoint.FIT_KIDZ_4_FUN}
            className="text-base leading-6 text-gray-500 hover:text-gray-900"
          >
            Fit Kidz 4 Fun
          </StyledLink>
        </div>
        <div className="px-5 py-2">
          <StyledLink
            to={EndPoint.JOBS_AT_FIT_KIDZ_4_FUN}
            className="text-base leading-6 text-gray-500 hover:text-gray-900"
          >
            Jobs
          </StyledLink>
        </div>
        <div className="px-5 py-2">
          <StyledLink
            to={EndPoint.PARTICIPATING_SCHOOLS}
            className="text-base leading-6 text-gray-500 hover:text-gray-900"
          >
            Participating Schools and Community Centers
          </StyledLink>
        </div>
      </nav>
      <div className="mt-8 flex justify-center">
        <a
          href="https://www.facebook.com/CortizFitness/"
          className="text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Facebook</span>
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
          </svg>
        </a>
        <a
          href="https://www.instagram.com/cortizfitness/"
          className="ml-6 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Instagram</span>
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        </a>
        <a
          href="https://www.youtube.com/channel/UCdObpVYDs2F-QM_Cl0jHKiA"
          className="ml-6 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Youtube</span>
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
            <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
          </svg>
        </a>
      </div>
      <div className="mt-8">
        <p className="text-center text-base leading-6 text-gray-400">
          &copy; {new Date().getFullYear()} Cortiz Fitness. All rights reserved.
        </p>
      </div>
    </div>
  </div>
)
