import React from 'react'
import { navigate } from 'gatsby'
import { useSpring, animated } from 'react-spring'

import { Location } from './nav.types'

export enum EndPoint {
  CORTIZ_FITNESS_HOME = '/cortiz-fitness-home',
  CORTIZ_FITNESS_SIGN_UP = '/cortiz-fitness-sign-up',
  FIT_KIDZ_4_FUN_REGISTRATION = '/fit-kidz-4-fun-registration',
  CONTACT_CORTIZ_FITNESS_HOME = '/contact-cortiz-fitness',
  CONTACT_FIT_KIDZ_4_FUN = '/contact-fit-kidz-4-fun',
  GYM_LOCATION = '/gym-location',
  TRAINER_SCHEDULE = '/trainer-schedule',
  CORTIZ_FITNESS_PRICING = '/cortiz-fitness-pricing',
  FIT_KIDZ_4_FUN = '/fit-kidz-4-fun',
  JOBS_AT_FIT_KIDZ_4_FUN = '/jobs-at-fit-kidz-4-fun',
  FIT_KIDZ_4_FUN_PRICING = '/fit-kidz-plans',
  PARTICIPATING_SCHOOLS = '/participating-schools',
  MEET_THE_TEAM = '/meet-the-team',
  AFTER_SCHOOL_PROGRAMS = '/after-school-programs',
  SUMMER_CAMP = '/summer-camp',
}

export const HeaderNav = ({ location }: { location: Location }) => {
  const [isCfMenuVisible, toggleIsCfMenuVisible] = React.useState(false)
  const [isFk4fMenuVisible, toggleIsFk4fMenuVisible] = React.useState(false)

  const springCfMenu = useSpring({
    opacity: isCfMenuVisible ? 1 : 0,
    marginTop: isCfMenuVisible ? 0 : -1000,
  })
  const springFk4fMenu = useSpring({
    opacity: isFk4fMenuVisible ? 1 : 0,
    marginTop: isFk4fMenuVisible ? 0 : -1000,
  })

  const { pathname } = location

  const { transform: xFormCfCarrot } = useSpring({
    opacity: isCfMenuVisible ? 1 : 0,
    transform: `perspective(600px) rotateX(${isCfMenuVisible ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  })

  const { transform: xFormFk4fCarrot } = useSpring({
    opacity: isCfMenuVisible ? 1 : 0,
    transform: `perspective(600px) rotateX(${isFk4fMenuVisible ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  })

  return (
    <div className="z-20 relative">
      <div
        className={`flex relative z-30 bg-white -mt-4 ${
          isCfMenuVisible || isFk4fMenuVisible ? 'shadow' : ''
        }`}
      >
        <div className="max-w-7xl mx-auto flex items-center px-4 py-6 sm:px-6 lg:px-8">
          <button
            type="button"
            onClick={() => {
              toggleIsCfMenuVisible(!isCfMenuVisible)
              toggleIsFk4fMenuVisible(false)
            }}
            className="ml-8 cursor-pointer font-medium text-xl text-gray-500 hover:text-primary-600 focus:outline-none focus:text-primary-600 group inline-flex items-center space-x-2 leading-6"
          >
            <span>Cortiz Fitness</span>
            <animated.span
              style={{
                opacity: 1,
                transform: xFormCfCarrot,
              }}
            >
              <svg
                className="text-gray-400 h-5 w-5 group-hover:text-primary-600 group-focus:text-primary-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </animated.span>
          </button>
          <button
            type="button"
            onClick={() => {
              toggleIsFk4fMenuVisible(!isFk4fMenuVisible)
              toggleIsCfMenuVisible(false)
            }}
            className="ml-8 cursor-pointer font-medium text-xl text-gray-500 hover:text-accent-400 focus:outline-none focus:text-accent-400 group inline-flex items-center space-x-2 leading-6"
          >
            <span>Fit Kidz 4 Fun</span>
            <animated.span
              style={{
                opacity: 1,
                transform: xFormFk4fCarrot,
              }}
            >
              <svg
                className="text-gray-400 h-5 w-5 group-hover:text-accent-400 group-focus:text-accent-400 transition ease-in-out duration-150"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </animated.span>
          </button>
        </div>
      </div>

      <animated.div
        style={springCfMenu}
        className="absolute inset-x-0 transform shadow-lg"
      >
        <div className="bg-white">
          <div className="max-w-7xl mx-auto grid row-gap-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
            <div
              onClick={() => navigate(EndPoint.CORTIZ_FITNESS_HOME)}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.CORTIZ_FITNESS_HOME
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-primary-600 text-white sm:h-12 sm:w-12">
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z"
                      />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-800">
                      Home
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Get a better understanding about the services offered by
                      Cortiz Fitness.
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.CORTIZ_FITNESS_HOME
                        ? 'text-primary-600'
                        : 'text-yellow-400'
                    }`}
                  >
                    Learn more &rarr;
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate('/gym-location')}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.GYM_LOCATION
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-primary-600 text-white sm:h-12 sm:w-12">
                    <svg
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-800">
                      Gym Location
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Where and when to find us. Bussiness hours and address
                      info.
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.GYM_LOCATION
                        ? 'text-primary-600'
                        : 'text-yellow-400'
                    }`}
                  >
                    Find us here &rarr;
                  </p>
                </div>
              </div>
            </div>
            {/* <div
              onClick={() => navigate(EndPoint.TRAINER_SCHEDULE)}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.TRAINER_SCHEDULE
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-primary-600 text-white sm:h-12 sm:w-12">
                    <svg
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M24 2v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm1 11.729l.855-.791c1 .484 1.635.852 2.76 1.654 2.113-2.399 3.511-3.616 6.106-5.231l.279.64c-2.141 1.869-3.709 3.949-5.967 7.999-1.393-1.64-2.322-2.686-4.033-4.271z" />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium 800">
                      Trainer Schedule
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Check session availability to get a better idea of when to
                      book yours.
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.TRAINER_SCHEDULE
                        ? 'text-primary-600'
                        : 'text-yellow-400'
                    }`}
                  >
                    What&apos;s available &rarr;
                  </p>
                </div>
              </div>
            </div> */}
            <div
              onClick={() => navigate('/cortiz-fitness-pricing')}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.CORTIZ_FITNESS_PRICING
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-primary-600 text-white sm:h-12 sm:w-12">
                    <svg
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="white"
                    >
                      <path d="M12.628 21.412l5.969-5.97 1.458 3.71-12.34 4.848-4.808-12.238 9.721 9.65zm-1.276-21.412h-9.352v9.453l10.625 10.547 9.375-9.375-10.648-10.625zm4.025 9.476c-.415-.415-.865-.617-1.378-.617-.578 0-1.227.241-2.171.804-.682.41-1.118.584-1.456.584-.361 0-1.083-.408-.961-1.218.052-.345.25-.697.572-1.02.652-.651 1.544-.848 2.276-.106l.744-.744c-.476-.476-1.096-.792-1.761-.792-.566 0-1.125.227-1.663.677l-.626-.627-.698.699.653.652c-.569.826-.842 2.021.076 2.938 1.011 1.011 2.188.541 3.413-.232.6-.379 1.083-.563 1.475-.563.589 0 1.18.498 1.078 1.258-.052.386-.26.763-.621 1.122-.451.451-.904.679-1.347.679-.418 0-.747-.192-1.049-.462l-.739.739c.463.458 1.082.753 1.735.753.544 0 1.087-.201 1.612-.597l.54.538.697-.697-.52-.521c.743-.896 1.157-2.209.119-3.247zm-9.678-7.476c.938 0 1.699.761 1.699 1.699 0 .938-.761 1.699-1.699 1.699-.938 0-1.699-.761-1.699-1.699 0-.938.761-1.699 1.699-1.699z" />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-800">
                      Pricing
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Workout plans and individual sessions available.
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.CORTIZ_FITNESS_PRICING
                        ? 'text-primary-600'
                        : 'text-yellow-400'
                    }`}
                  >
                    How much &rarr;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50">
          <div className="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
            <div className="flow-root">
              <div className="flow-root">
                <div
                  onClick={() => navigate(EndPoint.CONTACT_CORTIZ_FITNESS_HOME)}
                  className={`-m-3 p-3 space-x-3 flex items-center rounded-md text-base leading-6 font-medium text-gray-500 hover:bg-gray-100 cursor-pointer ${
                    pathname === EndPoint.CONTACT_CORTIZ_FITNESS_HOME
                      ? 'shadow-md'
                      : 'hover:shadow-md'
                  }`}
                >
                  <svg
                    className="h-6 w-6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#cccccc"
                  >
                    <path d="M23 0l-4.5 16.5-6.097-5.43 5.852-6.175-7.844 5.421-5.411-1.316 18-9zm-11 12.501v5.499l2.193-3.323-2.193-2.176zm-8.698 6.825l-1.439-.507 5.701-5.215 1.436.396-5.698 5.326zm3.262 4.287l-1.323-.565 4.439-4.503 1.32.455-4.436 4.613zm-4.083.387l-1.481-.507 8-7.89 1.437.397-7.956 8z" />
                  </svg>
                  <span>Contact Us</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
      <animated.div
        style={springFk4fMenu}
        className="absolute inset-x-0 transform shadow-lg"
      >
        <div className="bg-white">
          <div className="max-w-7xl mx-auto grid row-gap-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
            <div
              onClick={() => navigate('/fit-kidz-4-fun')}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.FIT_KIDZ_4_FUN
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-accent-400 text-white sm:h-12 sm:w-12">
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z"
                      />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-800">
                      Home
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Get a better understanding about the services offered by
                      Fit Kidz 4 Fun.
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.FIT_KIDZ_4_FUN
                        ? 'text-accent-400'
                        : 'text-primary-500'
                    }`}
                  >
                    Learn more &rarr;
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate(EndPoint.AFTER_SCHOOL_PROGRAMS)}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.AFTER_SCHOOL_PROGRAMS
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-accent-400 text-white sm:h-12 sm:w-12">
                    <svg
                      className="h-6 w-6"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    >
                      <path d="M9.082 2.303A2.996 2.996 0 0112 0a2.997 2.997 0 012.923 2.316c1.701.422 3.117 1.306 4.056 2.81.748 1.199 1.054 2.393 1.553 6.089 1.002.265 1.634.865 1.912 1.76.263.846.516 3.591.554 5.029.035 1.473-.395 2.64-1.881 3.131l-.01.003c-.498 3.078-3.527 2.848-4.527 2.859-1.444.009-2.692-.004-4.576-.011-1.198.004-5.325.013-5.804-.016-1.984-.158-3.03-1.228-3.297-2.829-1.144-.363-1.904-1.3-1.904-2.805 0-1.27.205-3.603.482-5.056.233-1.083.886-1.775 1.994-2.067.47-3.648.804-5.189 1.868-6.588.951-1.251 2.245-1.956 3.739-2.322M6.255 7.126c-.436 1.241-.839 4.666-.991 5.915-.331.02-.67.032-1.004.062-.688.073-.765.255-.845.727-.274 1.602-.413 3.459-.415 4.565.01.593.204.797.695.887.156.027.929.09 1.1.105.083 2.411.128 2.588 2.649 2.611 1.335.008 4.56-.011 4.56-.011 2.637.01 3.49.021 4.862.008 2.281-.022 2.273-.42 2.347-2.607.332-.029.664-.053.995-.091.836-.118.812-.542.784-1.39a31.41 31.41 0 00-.425-4.195c-.068-.341-.178-.486-.569-.57-.274-.062-.97-.085-1.252-.102-.124-1-.548-4.579-.991-5.852-.877-2.523-3.084-3.19-5.777-3.19-2.65 0-4.843.628-5.723 3.128m11.746 10.863c-.012 1.923-.901 2.937-2.888 2.998a322.1 322.1 0 01-6.217 0C7 20.926 6.042 19.823 6 18.059v-4.068h12.001v3.998zm-7-2.998h-4c0 1.036-.023 2.071.001 3.106.045 1.318.711 1.85 1.915 1.89 2.059.021 4.118.019 6.176 0 1.383-.043 1.895-.565 1.909-2.001v-2.995h-4.001v2.998a1.001 1.001 0 01-2 0v-2.998zm.446-8.196c-1.944.149-2.953.773-3.213 5.208-.062.632-.961.629-1-.019.013-.702.153-1.945.351-2.804.359-1.542 1.033-2.742 2.543-3.185.974-.286 2.781-.285 3.749 0 1.455.426 2.133 1.555 2.496 3.037.244 1 .392 2.656.366 3.016a.5.5 0 01-.993.01c-.306-3.096-.336-5.126-3.255-5.267l.676 2.335a1.195 1.195 0 11-2.337.025l.617-2.356zm.554 1.994a.598.598 0 110 1.196.598.598 0 010-1.196m1.791-6.683A1.985 1.985 0 0011.995.999c-.814.01-1.46.46-1.783 1.102a15.367 15.367 0 013.58.005" />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-800">
                      After School Programs
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Sports and Fitness programs located in central Florida for
                      kids ages 5-12.
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.AFTER_SCHOOL_PROGRAMS
                        ? 'text-accent-400'
                        : 'text-primary-500'
                    }`}
                  >
                    Register the family &rarr;
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate(EndPoint.SUMMER_CAMP)}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.SUMMER_CAMP
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-accent-400 text-white sm:h-12 sm:w-12">
                    <svg
                      viewBox="0 0 24 24"
                      className="h-6 w-6"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="white"
                    >
                      <path d="M6 23h-3v-5.486c-1.827-.518-3-2.24-3-3.988 0-.585.129-1.172.407-1.716-.323-1.661.657-3.344 2.356-3.881l.018-.005c.773-1.102 2.057-1.826 3.535-1.898l5.666-5.026 12.018 10.661-1.33 1.493-10.688-9.482-3.274 2.905c.305.162.591.358.849.589l2.415-2.142 9.026 7.989v9.987h-2.998v-7h-5l-.008 7h-5.992v-6.061c.546-.125 1.034-.463 1.317-1.044 2.096.786 3.746-2.273 1.82-3.562 1.073-1.237.188-2.747-1.208-2.525-.673-2.384-4.178-2.384-4.851 0-1.31-.235-2.295 1.094-1.385 2.291-1.654 1.38-.162 4.084 1.872 3.473.214.74.794 1.2 1.435 1.362v6.066zm.451-10.678c1.493-1.023 3.242.768 2.303 2.226.002-1.473-.853-2.282-2.303-2.226m-2.119 1.191c-.668-1.002-.34-2.366.705-2.968.589-.338 1.33-.369 1.953-.07 1.06.507-2.743-.678-2.658 3.038" />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-800">
                      Summer Camp
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Kids from all schools are welcomed to participate!
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.SUMMER_CAMP
                        ? 'text-accent-400'
                        : 'text-primary-500'
                    }`}
                  >
                    Plan for the summer &rarr;
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate(EndPoint.FIT_KIDZ_4_FUN_PRICING)}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.FIT_KIDZ_4_FUN_PRICING
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-accent-400 text-white sm:h-12 sm:w-12">
                    <svg
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="white"
                    >
                      <path d="M12.628 21.412l5.969-5.97 1.458 3.71-12.34 4.848-4.808-12.238 9.721 9.65zm-1.276-21.412h-9.352v9.453l10.625 10.547 9.375-9.375-10.648-10.625zm4.025 9.476c-.415-.415-.865-.617-1.378-.617-.578 0-1.227.241-2.171.804-.682.41-1.118.584-1.456.584-.361 0-1.083-.408-.961-1.218.052-.345.25-.697.572-1.02.652-.651 1.544-.848 2.276-.106l.744-.744c-.476-.476-1.096-.792-1.761-.792-.566 0-1.125.227-1.663.677l-.626-.627-.698.699.653.652c-.569.826-.842 2.021.076 2.938 1.011 1.011 2.188.541 3.413-.232.6-.379 1.083-.563 1.475-.563.589 0 1.18.498 1.078 1.258-.052.386-.26.763-.621 1.122-.451.451-.904.679-1.347.679-.418 0-.747-.192-1.049-.462l-.739.739c.463.458 1.082.753 1.735.753.544 0 1.087-.201 1.612-.597l.54.538.697-.697-.52-.521c.743-.896 1.157-2.209.119-3.247zm-9.678-7.476c.938 0 1.699.761 1.699 1.699 0 .938-.761 1.699-1.699 1.699-.938 0-1.699-.761-1.699-1.699 0-.938.761-1.699 1.699-1.699z" />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-800">
                      Pricing
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Kids from all schools are welcomed to participate!
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.FIT_KIDZ_4_FUN_PRICING
                        ? 'text-accent-400'
                        : 'text-primary-500'
                    }`}
                  >
                    Plan for the summer &rarr;
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate(EndPoint.PARTICIPATING_SCHOOLS)}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.PARTICIPATING_SCHOOLS
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-accent-400 text-white sm:h-12 sm:w-12">
                    <svg
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="white"
                    >
                      <path d="M22 22h1v2h-22v-2h1v-8h20v8zm-7-5h-6v6h2v-5h2v5h2v-6zm-9 3h-2v2h2v-2zm14 0h-2v2h2v-2zm-14-4h-2v2h2v-2zm14 0h-2v2h2v-2zm4-3h-24l3-7h3.943l-2.601 2.229.66.771 5.998-5.143v-3.857h5l-1 1.491 1 1.509h-4l6.999 6 .661-.771-2.602-2.229h3.942l3 7zm-12-6.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm0 2.5h1v.8h-1.763v-1.8h.763v1z" />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-800">
                      Participating Schools
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      We&apos;re in community centers and over 23 OCPS schools
                      in Orlando.
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.PARTICIPATING_SCHOOLS
                        ? 'text-accent-400'
                        : 'text-primary-500'
                    }`}
                  >
                    Check availability &rarr;
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate(EndPoint.JOBS_AT_FIT_KIDZ_4_FUN)}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.JOBS_AT_FIT_KIDZ_4_FUN
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-accent-400 text-white sm:h-12 sm:w-12">
                    <svg
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="white"
                    >
                      <path d="M13.403 24h-13.403v-22h3c1.231 0 2.181-1.084 3-2h8c.821.916 1.772 2 3 2h3v9.15c-.485-.098-.987-.15-1.5-.15l-.5.016v-7.016h-4l-2 2h-3.897l-2.103-2h-4v18h9.866c.397.751.919 1.427 1.537 2zm5.097-11c3.035 0 5.5 2.464 5.5 5.5s-2.465 5.5-5.5 5.5c-3.036 0-5.5-2.464-5.5-5.5s2.464-5.5 5.5-5.5zm0 2c1.931 0 3.5 1.568 3.5 3.5s-1.569 3.5-3.5 3.5c-1.932 0-3.5-1.568-3.5-3.5s1.568-3.5 3.5-3.5zm2.5 4h-3v-3h1v2h2v1zm-15.151-4.052l-1.049-.984-.8.823 1.864 1.776 3.136-3.192-.815-.808-2.336 2.385zm6.151 1.052h-2v-1h2v1zm2-2h-4v-1h4v1zm-8.151-4.025l-1.049-.983-.8.823 1.864 1.776 3.136-3.192-.815-.808-2.336 2.384zm8.151 1.025h-4v-1h4v1zm0-2h-4v-1h4v1zm-5-6c0 .552.449 1 1 1 .553 0 1-.448 1-1s-.447-1-1-1c-.551 0-1 .448-1 1z" />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-800">
                      Jobs Openings
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Interested in being apart of the team and helping us make
                      a positive impact on students?
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.JOBS_AT_FIT_KIDZ_4_FUN
                        ? 'text-accent-400'
                        : 'text-primary-500'
                    }`}
                  >
                    Apply here &rarr;
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate('/meet-the-team')}
              className={`-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50 cursor-pointer ${
                pathname === EndPoint.MEET_THE_TEAM
                  ? 'shadow-md'
                  : 'hover:shadow-md'
              }`}
            >
              <div className="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
                <div className="flex-shrink-0 pr-2 md:pb-1 mt-2 md:mt-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-accent-400 text-white sm:h-12 sm:w-12">
                    <svg
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="white"
                    >
                      <path d="M1.981 8.444h20.038c.398 0 .747.264.856.648l1.105 3.904.02.139c0 .209-.127.402-.33.48l-.001.001c-.24.092-.511-.005-.635-.231l-1.144-2.071-.328 7.967c-.017.403-.347.719-.749.719h-.001c-.393 0-.716-.306-.746-.698-.068-.865-.249-2.933-.304-3.752-.022-.34-.271-.54-.541-.54-.242 0-.514.2-.537.54-.055.819-.236 2.887-.304 3.752-.03.392-.352.698-.746.698h-.001c-.402 0-.732-.316-.749-.719-.086-2.08-.435-8.736-.435-8.736h-1.669s-.349 6.656-.435 8.736c-.017.402-.347.719-.749.719h-.001c-.394 0-.716-.306-.746-.698-.068-.865-.249-2.933-.304-3.752-.023-.34-.295-.54-.537-.54h-.004c-.242 0-.515.2-.537.54-.055.819-.236 2.887-.304 3.752-.03.392-.353.698-.746.698h-.001c-.402 0-.732-.316-.749-.719-.086-2.08-.435-8.736-.435-8.736h-1.681s-.349 6.656-.435 8.736c-.017.403-.347.719-.749.719h-.001c-.394 0-.716-.306-.746-.698-.068-.865-.249-2.933-.304-3.752-.023-.34-.295-.54-.537-.54-.27 0-.519.2-.541.54-.055.819-.236 2.887-.304 3.752-.03.392-.353.698-.746.698h-.001c-.402 0-.732-.316-.749-.719l-.328-7.967-1.144 2.071c-.124.226-.395.323-.635.231l-.001-.001c-.203-.078-.33-.271-.33-.48l.02-.139 1.105-3.904c.109-.384.458-.648.856-.648zm3.019-4.444c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm14 0c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm-6.994 0c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2z" />
                    </svg>
                  </span>
                </div>
                <div className="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
                  <div className="space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-800">
                      Meet the team
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Have peace of mind knowning your children are with
                      professionals
                    </p>
                  </div>
                  <p
                    className={`text-sm leading-5 font-medium ${
                      pathname === EndPoint.MEET_THE_TEAM
                        ? 'text-accent-400'
                        : 'text-primary-500'
                    }`}
                  >
                    See who we are &rarr;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50">
          <div className="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
            <div className="flow-root">
              <div
                onClick={() => navigate(EndPoint.CONTACT_FIT_KIDZ_4_FUN)}
                className="-m-3 p-3 space-x-3 flex items-center rounded-md text-base leading-6 font-medium text-gray-500 hover:bg-gray-100 cursor-pointer hover:shadow-md"
              >
                <svg
                  className="h-6 w-6"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#cccccc"
                >
                  <path d="M23 0l-4.5 16.5-6.097-5.43 5.852-6.175-7.844 5.421-5.411-1.316 18-9zm-11 12.501v5.499l2.193-3.323-2.193-2.176zm-8.698 6.825l-1.439-.507 5.701-5.215 1.436.396-5.698 5.326zm3.262 4.287l-1.323-.565 4.439-4.503 1.32.455-4.436 4.613zm-4.083.387l-1.481-.507 8-7.89 1.437.397-7.956 8z" />
                </svg>
                <span>Contact Us</span>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  )
}
