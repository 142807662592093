import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import SEO from './seo'
import { HeaderNav } from './nav/header-nav'
import { Footer } from './nav/footer'
import { Location } from '@reach/router'
import { Location as LocationType } from '../components/nav/nav.types'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    font-family: 'Varela Round', sans-serif;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Varela Round', serif;
  }
`

type Props = {
  seoTitle: string
  children: React.ReactNode
}

const Layout = (props: Props) => (
  <>
    <GlobalStyle />
    <SEO title={props.seoTitle} />
    <Location>
      {({ location }: { location: LocationType }) => (
        <div className="relative overflow-hidden">
          <div className="relative pt-4">
            <HeaderNav location={location} />
            <main className="overflow-auto">{props.children}</main>
          </div>
        </div>
      )}
    </Location>
    <Footer />
    <ToastContainer />
  </>
)

export default Layout
