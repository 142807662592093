import { constVoid, Lazy } from 'fp-ts/lib/function'
import { io } from 'fp-ts/lib/IO'

/**
 * IO constructor function
 *
 */
export const newIO = <A>(f: Lazy<A>) => io.of(f())

/**
 * noOp IO function
 */
export const noOpIO = io.of(constVoid())
