/**
 * base styled componets with 'Varela Round' font family
 */
import styled from 'styled-components'
import { Link } from 'gatsby'

export const StyledP = styled.p`
  font-family: 'Varela Round', sans-serif;
`

export const StyledH2 = styled.h2`
  font-family: 'Varela Round', sans-serif;
`

export const StyledSpan = styled.span`
  font-family: 'Varela Round', sans-serif;
`

export const StyledLink = styled(Link)`
  font-family: 'Varela Round', sans-serif;
`
